;(function () {
  'use strict'

  let root = document

  document.addEventListener('DOMContentLoaded', function () {
    function initBBIS() {
      const pageId = BLACKBAUD.api.pageInformation.pageId
      const partId = document.querySelector('.BBDonationApiContainer') ? document.querySelector('.BBDonationApiContainer').dataset.partid : null
      const ClientSitesID = document.querySelector('.BBDonationApiContainer')
        ? document.querySelector('.BBDonationApiContainer').getAttribute('ClientSitesID')
        : null
      const ds = new BLACKBAUD.api.DonationService(partId)
      const bbForm = document.getElementById('form1')
      const theForm = document.getElementById('baylor__adf')
      const theConfirmation = document.getElementById('baylor__adf-confirmation')
      const donationId = getUrlVars().t
      let els
      let el
      let currentStep = 0
      let giftRowIndex = 0
      let isReturning = false

      function removeSavedData() {
        localStorage.removeItem('designations')
        localStorage.removeItem('specifiedFund')
        localStorage.removeItem('savedHTML')
        FormPersistence.clearStorage(bbForm, {
          uuid: 'savedForm',
          useSessionStorage: true,
        })
      }

      function initForm() {
        root = theForm

        const searchParams = new URLSearchParams(window.location.search)
        const filter = Array.prototype.filter

        let donation = null
        let bbcheckout = null
        let checkoutModel = JSON.parse(checkoutData)

        let isAnotherGift = false
        let editingGift = false
        let isPreSelectedFundOnly = false
        let giftIndex = 0
        let allDesignations = []
        let displayDesignations = []
        let designationUnits = {}
        let designationAreas = {}
        let designationFunds = {}

        const academicFunds = 'Academic Areas'
        const campusFunds = 'Campus Life'
        const featuredFunds = 'Main Giving Page (Featured)'
        const priorityFunds = 'University Priorities'

        const fundCategories = {
          'Academic Areas': 'Academics',
          'Campus Life': 'Campus Life',
          'Main Giving Page (Featured)': 'Featured',
        }

        let unitDropdowns = {}
        unitDropdowns[academicFunds] = 'unit-select-academics'
        unitDropdowns[campusFunds] = 'unit-select-campus-life'
        let fundDropdowns = {}
        fundDropdowns[featuredFunds] = 'fund-select-featured'
        fundDropdowns[priorityFunds] = 'fund-select-featured'
        let selectedUnit

        const designationButtons = getAll('[name="designation"]')
        const giftUnits = getAll('.unit-select')
        const giftAreas = getAll('.area-select')
        const giftFunds = getAll('.fund-select')
        const searchFunds = getAll('.designation-search-select')
        const specifyFund = document.getElementById('specify-fund-checkbox')
        const giftAmountOtherButton = document.getElementById('gift-amount-other-button')
        const giftAmountOther = document.getElementById('gift-amount-other')
        const frequencyButtons = getAll('input[name="gift-type"]')
        const paymentButtons = getAll('input[name="gift-payment-type"]')
        const stepMarkers = getAll('.step-item')
        const growingFields = getAll('.is-growing')
        const addButtons = getAll('.step-action-add')
        const nextButtons = getAll('.step-action-next')
        const prevButtons = getAll('.step-action-previous')
        const cancelButtons = getAll('.step-action-cancel')
        const isTribute = document.getElementById('is-tribute')
        const userEnteredFinder = document.getElementById('donor-finder-number')
        let urlFinderNumber = document.getElementById('finder-number')

        let editButtons = null
        let removeButtons = null
        let giftFrequency = ''
        let appealID = ''

        const numPaymentsField = document.getElementById('gift-num-payments')

        const formSteps = []
        formSteps.push(theForm.querySelector('.js-step[data-step="1"]'))
        formSteps.push(theForm.querySelector('.js-step[data-step="2"]'))
        formSteps.push(theForm.querySelector('.js-step[data-step="3"]'))
        formSteps.push(theForm.querySelector('.js-step[data-step="4"]'))

        function ui() {
          // Collapsibles
          bulmaCollapsible.attach()
          els = getAll('[data-action="collapse"]')
          if (els.length > 0) {
            els.forEach(function (el) {
              el.addEventListener('click', function (event) {
                let collapseCheckbox = el.previousElementSibling
                if (collapseCheckbox) {
                  collapseCheckbox.click()
                }
              })
            })
          }

          els = getAll('.has-collapse-control')
          if (els.length > 0) {
            els.forEach(function (el) {
              const collapsible = document.getElementById(el.getAttribute('data-collapse'))
              if (collapsible) {
                const elems = el.querySelectorAll('input[type="radio"]')

                if (elems.length > 0) {
                  elems.forEach(function (el) {
                    el.addEventListener('click', function (event) {
                      if (hasClass(el, 'is-collapse-control')) {
                        activateElement(collapsible)
                      } else {
                        deactivateElement(collapsible)
                      }
                    })
                  })
                }
              }
            })
          }

          // Tabs
          els = getAll('.tab')
          if (els.length > 0) {
            els.forEach(function (el) {
              el.addEventListener('click', function (event) {
                let tabContentID = this.getAttribute('data-tab')
                let tabContent = tabContentID ? document.getElementById(tabContentID) : null

                if (tabContent) {
                  let otherTabs = getSiblings(tabContent)
                  if (otherTabs.length > 0) {
                    otherTabs.forEach(function (el) {
                      deactivateElement(el)
                    })
                  }
                  activateElement(tabContent)
                }
              })
            })
          }
        }
        showUserEnteredFinder()
        getUrlFinderNumber()
        ui()

        //$.ajax({
        //  url: bbisURL + 'components/custom.ashx?handler=ZuriGroup.BU.ADF.BBIS.DesignationHandler',
        //  dataType: 'json',
        //})
        //.done(function (data) {
        //  console.log(data);
        //})
        //.fail(function(xhr, desc, err) {
        //  console.log('xhr: ' + xhr);
        //  console.log('desc: ' + desc);
        //  console.log('err: ' + err);
        //});

        function showUserEnteredFinder() {
          if (searchParams.has('showfinder')) {
            if (searchParams.get('showfinder') === '1') {
              showElement(userEnteredFinder)
            } else {
              hideElement(userEnteredFinder)
            }
          }
        }

        // Get Finder ID
        function getUrlFinderNumber() {
          const hasParams = function () {
            return searchParams.has('finder')
          }

          if (hasParams()) {
            const finderNumber = Number(searchParams.get('finder'))
            urlFinderNumber.value = finderNumber
          }
        }

        function getPreSelectedDesignation(designationId) {
          const designation = allDesignations.filter(function (obj) {
            return obj.lookupId === designationId || obj.altLookupId === designationId
          })
          return designation[0]
        }

        function getPreSelectedData() {
          const preSelectedDesignation = document.getElementById('js-preselected-designation')
          if (preSelectedDesignation) {
            return JSON.parse(preSelectedDesignation.dataset.designation)
          }
        }

        function hidePreSelectedDesignation() {
          let elems
          const preSelectedDesignation = document.getElementById('js-preselected-designation')

          if (preSelectedDesignation) {
            preSelectedDesignation.innerText = ''
            preSelectedDesignation.dataset.designation = ''
          }
          elems = getAll('.not-for-preselected')
          if (elems.length > 0) {
            elems.forEach(function (el) {
              showElement(el)
            })
          }
          elems = getAll('.for-preselected')
          if (elems.length > 0) {
            elems.forEach(function (el) {
              hideElement(el)
            })
          }
        }

        function showPreSelectedDesignation(designationId, editStatus, removeStatus) {
          let designation = getPreSelectedDesignation(designationId)

          if (designation) {
            let elems
            const preSelectedDesignation = document.getElementById('js-preselected-designation')

            if (preSelectedDesignation) {
              preSelectedDesignation.innerText = designation.name
              designation.editStatus = editStatus
              designation.removeStatus = removeStatus
              preSelectedDesignation.dataset.designation = JSON.stringify(designation)
            }
            elems = getAll('.not-for-preselected')
            if (elems.length > 0) {
              elems.forEach(function (el) {
                hideElement(el)
              })
            }
            elems = getAll('.for-preselected')
            if (elems.length > 0) {
              elems.forEach(function (el) {
                showElement(el)
              })
            }
          }
        }

        function addPreSelectedDonation(designationId, amount, edit, remove) {
          let preSelectedGift = {}
          let designation = getPreSelectedDesignation(designationId)

          if (designation) {
            preSelectedGift.designation = designation
            preSelectedGift.amount = Number(amount)
            preSelectedGift.edit = edit
            preSelectedGift.remove = remove
            addPreSelectedGiftRow(preSelectedGift)
            return true
          } else {
            return false
          }
        }

        function preSelectDonation() {
          const hasParams = function () {
            return searchParams.has('f')
          }

          if (hasParams() && !isReturning) {
            const preSelectedDesignations = searchParams.getAll('f')
            const preSelectedAmounts = searchParams.getAll('amount')
            const editStatus = searchParams.get('e')
            const removeStatus = searchParams.get('r')
            let giftCount = 0

            if (preSelectedDesignations.length > 0) {
              if (preSelectedDesignations.length === preSelectedAmounts.length) {
                // isPreSelectedGift = true;
                preSelectedDesignations.forEach(function (designationId, index) {
                  if (addPreSelectedDonation(designationId, preSelectedAmounts[index], editStatus, removeStatus)) {
                    giftCount++
                  }
                })
                if (giftCount > 0) {
                  displayNextStep()
                }
              } else if (preSelectedDesignations.length === 1 && preSelectedAmounts.length === 0) {
                isPreSelectedFundOnly = true
                showPreSelectedDesignation(preSelectedDesignations[0], editStatus, removeStatus)
              }
            }
          }
        }

        function preSelectCategory() {
          function selectCategory(dropdown, val) {
            selectOption(dropdown, val)
            setTimeout(function () {
              triggerEvent(dropdown, 'change')
            }, 100)
          }

          const hasParams = function () {
            return searchParams.has('c')
          }

          if (hasParams()) {
            const category = categoryIDs[searchParams.get('c')]

            if (category) {
              const categories = category.split('>')

              if (categories[0] === 'Campus Life') {
                theForm.querySelector('input[name="designation"][value="Campus Life"]').click()
                triggerEvent(theForm.querySelector('input[name="designation"][value="Campus Life"]'), 'click')
                selectCategory(document.getElementById(unitDropdowns[campusFunds]), categories[1])
              } else {
                selectCategory(document.getElementById(unitDropdowns[academicFunds]), categories[0])
                if (categories.length === 2) {
                  setTimeout(function () {
                    selectCategory(document.getElementById('area-select-academics'), categories[1])
                  }, 100)
                }
              }
            }
          }
        }

        function preSelectScode() {
          const sCode = searchParams.get('scode') || ''

          if (sCode !== '') {
            document.getElementById('scode').value = sCode
            getAppeals(sCode)
          }
        }

        function getAppeals(sCode) {
          function success(data) {
            const matchedAppeal = data.Rows.filter(function (obj) {
              return obj.Values[0] === sCode
            })

            if (matchedAppeal.length > 0) {
              appealID = matchedAppeal[0].Values[1]
            }
          }

          function error(errors) {
            return false
          }

          const qs = new BLACKBAUD.api.QueryService()
          qs.getResults(appealGUID, success, error)
        }

        function getDesignations() {
          let categories = []
          let categoryGroups = []
          const catOffset = 2

          function sortGiftLevels(levels) {
            let sortedLevels = levels.split(' | ')
            sortedLevels = chunkArray(sortedLevels, 3)
            sortedLevels.pop()
            sortedLevels = sortedLevels.sort(function (a, b) {
              return a[0] - b[0]
            })
            return sortedLevels
          }

          function getGiftLevels(guid, data) {
            let levels = ''
            const fundGroup = data.Rows.filter(function (obj) {
              return obj.Values[2] === guid
            })

            if (fundGroup.length > 0) {
              fundGroup.forEach(function (fund) {
                levels += levels.indexOf(fund.Values[9]) === -1 ? fund.Values[9] + ' | ' : ''
              })
            }
            levels = sortGiftLevels(levels)
            return levels
          }

          function getDesignationValues(values, data) {
            const isPriority = values[12] === 'True' ? true : false

            return {
              lookupId: values[0],
              altLookupId: values[1],
              guid: values[2],
              category: isPriority && values[4].indexOf(featuredFunds) === -1 ? featuredFunds : values[4],
              name: values[5],
              isFeatured: values[6],
              levels: values[9] !== '' ? getGiftLevels(values[2], data) : '',
              isMemTrib: values[10],
              redirectURL: values[11],
              isPriority: values[12],
            }
          }

          function removeArrayObj(arr, item) {
            return arr.filter(function (obj) {
              return obj.guid !== item.guid
            })
          }

          // Can only use the designation if lookup ID, GUID and name are present
          function removeBadData() {
            allDesignations = allDesignations.filter(function (obj) {
              return obj.hasOwnProperty('lookupId') && obj.hasOwnProperty('guid') && obj.hasOwnProperty('name')
            })
          }

          function getAllDesignations(data) {
            function isDuplicate(guid, category) {
              const found = allDesignations.filter(function (obj) {
                return obj.guid === guid
              })

              if (found.length > 0) {
                if (found[0].category.split('>').length < category.split('>').length) {
                  allDesignations = removeArrayObj(allDesignations, found[0])
                  return false
                } else {
                  return true
                }
              } else {
                return false
              }
            }

            data.Rows.forEach(function (el) {
              const guid = el.Values[2]
              const category = el.Values[4]
              const isActive = el.Values[7] === 'True' ? true : false
              const isPriority = el.Values[12] === 'True' ? true : false

              if (isActive && !isDuplicate(guid, category)) {
                allDesignations.push(getDesignationValues(el.Values, data))
              } else if (isActive && isPriority && category.indexOf(featuredFunds) > -1) {
                allDesignations.splice(
                  allDesignations.findIndex((item) => item.guid === guid),
                  1
                )
                allDesignations.push(getDesignationValues(el.Values, data))
              }
            })
          }

          function getDisplayDesignations() {
            displayDesignations = allDesignations.filter(function (obj) {
              return obj.category !== '' && (obj.category.indexOf(campusFunds) > -1 || obj.category.indexOf(academicFunds) > -1 || obj.isPriority === 'True')
            })
          }

          function getDesignationCategories() {
            Array.prototype.slice
              .call(allDesignations)
              .concat(Array.prototype.slice.call(displayDesignations))
              .forEach(function (obj) {
                let categories = obj.category.split('>')
                obj.categories = categories
              })
          }

          function getCategoryGroups() {
            categoryGroups = displayDesignations.reduce((r, a) => {
              r[a.categories[0]] = [...(r[a.categories[0]] || []), a]
              return r
            }, {})
          }

          function addOptions(dropdown, opts) {
            opts.forEach(function (el) {
              return typeof el === 'string' ? createOption(dropdown, el) : createDesignationOption(dropdown, el)
            })
          }

          function addGroupedOptions(dropdown, groups) {
            for (let group in groups) {
              createOptGroup(dropdown, group, groups[group])
            }
          }

          function populateUnitDropdown(category, units) {
            if (unitDropdowns[category]) {
              addOptions(document.getElementById(unitDropdowns[category]), units)
            }
          }

          function addLooseFunds(category, funds) {
            const looseFunds = funds.filter(function (obj) {
              return !obj.categories[1]
            })

            if (fundDropdowns[category]) {
              addOptions(document.getElementById(fundDropdowns[category]), looseFunds)
            }
          }

          function getGroups(funds) {
            let groups = {}

            funds.forEach(function (obj) {
              const group = obj.categories[obj.categories.length - 1]

              if (!groups[group]) {
                groups[group] = []
              }
              groups[group].push(obj)
            })
            return groups
          }

          function addGroupedFunds(category, funds) {
            const groupedFunds = funds.filter(function (obj) {
              return obj.categories[1]
            })

            const groups = getGroups(groupedFunds)
            addGroupedOptions(document.getElementById(fundDropdowns[category]), groups)
          }

          function populateFundDropdown(category, funds) {
            if (fundDropdowns[category]) {
              addLooseFunds(category, funds)
              addGroupedFunds(category, funds)
            }
          }

          function getFunds(categories) {
            categories.forEach(function (obj) {
              const category = obj.categories[obj.categories.length - 1]

              if (category) {
                if (!designationFunds[category]) {
                  designationFunds[category] = []
                }
                designationFunds[category].push(obj)
              }
            })

            for (let funds in designationFunds) {
              if (Array.isArray(designationFunds[funds])) {
                designationFunds[funds].sort(dynamicSort('name'))
              }
            }
          }

          function getAreas(category, units) {
            units.forEach(function (unit) {
              designationAreas[unit] = []

              const hasArea = categoryGroups[category].filter(function (obj) {
                return obj.categories[2] && obj.categories[0] !== featuredFunds && obj.categories[0] !== priorityFunds
              })

              hasArea.forEach(function (obj) {
                const area = obj.categories[2]

                if (obj.categories[1] === unit && designationAreas[unit].indexOf(area) === -1) {
                  designationAreas[unit].push(area)
                }
              })

              if (Array.isArray(designationAreas[unit])) {
                designationAreas[unit].sort()
              }
            })
          }

          function getUnits(category, categories) {
            designationUnits[category] = []
            categories.forEach(function (obj) {
              const unit = obj.categories[1]

              if (unit && designationUnits[category].indexOf(unit) === -1) {
                designationUnits[category].push(unit)
              }
            })

            if (Array.isArray(designationUnits[category])) {
              designationUnits[category].sort()
              populateUnitDropdown(category, designationUnits[category])
              getAreas(category, designationUnits[category])
            }
          }

          function getCategory(el) {
            return categories[el.indexOf('True', catOffset) - catOffset]
          }

          const doSearch = debounce(function (fuse) {
            let results = fuse.search('fund')
          }, 250)

          function useOrOperator(str) {
            return str.replace(/\s/g, ' | ')
          }

          function getSearchResults(fuse) {
            const searchBox = document.getElementById('designation-search')
            const searchField = document.getElementById('designation-search-field')
            const searchResults = document.getElementById('designation-search-results')
            const searchResultsLabels = getAll('.js-designation-search-result-count')
            const noResultsFound = document.getElementById('no-results-found')

            let len = searchResults.length
            for (let i = 1; i < len; i++) {
              searchResults.remove(1)
            }

            hideElement(searchField)

            if (searchBox.value.length > 2) {
              const searchTerms = useOrOperator(searchBox.value).trim()
              let results = fuse.search(searchTerms)
              let resultsLen = results.length

              if (resultsLen > 0) {
                hideElement(noResultsFound)
                showElement(searchField)
                addOptions(searchResults, results, resultsLen)
                searchResultsLabels.forEach(function (el) {
                  el.innerText = 'We found ' + resultsLen + ' matching funds'
                })
              } else {
                hideElement(searchField)
                showElement(noResultsFound)
              }
            } else {
              hideElement(searchField)
              hideElement(noResultsFound)
            }
          }

          function searchDesignations() {
            const searchBox = document.getElementById('designation-search')
            const options = {
              shouldSort: true,
              threshold: 0.3,
              minMatchCharLength: 3,
              keys: ['name'],
              distance: 10000,
              useExtendedSearch: true,
            }
            let fuse = new Fuse(allDesignations, options)

            searchBox.addEventListener('focus', function () {
              showElement(specifyFund)
            })

            searchBox.addEventListener(
              'keyup',
              debounce(function () {
                getSearchResults(fuse)
              }, 250)
            )

            searchBox.addEventListener('change', function () {
              getSearchResults(fuse)
            })
          }

          function processDesignations(data) {
            getAllDesignations(data)
            removeBadData()
            getDisplayDesignations()
            getDesignationCategories()
            getCategoryGroups()
            for (let category in categoryGroups) {
              if (category !== featuredFunds && category !== priorityFunds) {
                getUnits(category, categoryGroups[category])
              }
              getFunds(categoryGroups[category])
              if (category === featuredFunds || category === priorityFunds) {
                populateFundDropdown(category, categoryGroups[category])
              }
            }
            searchDesignations()
            preSelectCategory()
            preSelectDonation()
            preSelectScode()
            showPage()
          }

          function success(data) {
            // Cache designation data for faster loading
            localStorage.setItem('designations', JSON.stringify(data))
            processDesignations(data)
            // getEditorContent();
            // getPublicKey();
            // initCheckout();
            InitializeBBCheckout()
          }

          function error(errors) {
            return false
          }

          //if (localStorage.getItem('designations') !== null) {
          // Use cached data if available
          //   processDesignations(JSON.parse(localStorage.getItem('designations')));
          //} else {
          // Otherwise get data from CRM
          const qs = new BLACKBAUD.api.QueryService()
          qs.getResults(queryGUID, success, error)
          //}
        }
        getDesignations()

        function getCodeTables() {
          const cts = new BLACKBAUD.api.CodeTableService()
          const usesCodeTable = getAll('[data-code-table]')
          let container = null

          function success(data) {
            const els = container.querySelectorAll('input')
            if (els.length > 0) {
              els.forEach(function (el, index) {
                el.value = data[index].Description
                if (el.type.toLowerCase() === 'radio') {
                  el.nextElementSibling.innerText = el.value
                }
              })
            }
          }

          function error(errors) {
            return false
          }

          if (usesCodeTable.length > 0) {
            usesCodeTable.forEach(function (el) {
              container = el
              cts.getEntries(el.getAttribute('data-code-table'), success, error)
            })
          }
        }
        getCodeTables()

        function getCountries() {
          const cs = new BLACKBAUD.api.CountryService()
          const countrySelect = document.getElementById('donor-country')

          function handleCountryChange() {
            const provinceTextControl = document.getElementById('control--donor-province-intl--text')
            const provinceText = document.getElementById('donor-province-intl--text')
            const provinceSelectControl = document.getElementById('control--donor-province-intl--select')
            const provinceSelect = document.getElementById('donor-province-intl--select')

            cs.getStates(this.value, function (states) {
              clearOptions(provinceSelect)
              clearValue(provinceText)

              if (states.length > 0) {
                states.forEach(function (state) {
                  createISOOption(provinceSelect, state)
                })
                hideElement(provinceTextControl)
                showElement(provinceSelectControl)
              } else {
                hideElement(provinceSelectControl)
                showElement(provinceTextControl)
              }
            })
          }

          cs.getCountries(function (countries) {
            countries.forEach(function (country) {
              createISOOption(countrySelect, country)
            })
          })

          countrySelect.addEventListener('change', handleCountryChange)
        }
        getCountries()

        function createCheckoutForm() {
          const paymentForm = document.getElementById('paymentForm')

          if (!paymentForm) {
            const newForm = document.createElement('form')

            newForm.setAttribute('id', 'paymentForm')
            newForm.setAttribute('method', 'get')
            newForm.dataset.formtype = 'bbCheckout'
            newForm.setAttribute('data-disable-submit', 'false')
            newForm.novalidate = true
            document.body.appendChild(newForm)
          }
        }

        function checkoutFunctions() {
          // If you want to override the functionality of any below mentioned events, then you can write your required code in respective Checkout Events Block
          let checkoutEvents = {
            checkoutComplete: function (e) {
              hidePage()
              // Invoke after Payment will successfully Completed
              bbcheckout.postCheckoutFinish()
              setupConfirmation()
            },
            checkoutError: function (data) {
              console.log(data)
            },
            checkoutExpired: function () {
              enableNextButtons()
              showPage()
            },
            checkoutReady: function () {
              hidePage()
            },
            browserClose: function () {
              // If you Close the Browser. Then write code in this block.
            },
            checkoutCancel: function () {
              enableNextButtons()
              showPage()
            },
            checkoutLoaded: function () {
              hidePage()
            },
          }
          return checkoutEvents
        }

        function InitializeBBCheckout() {
          createCheckoutForm()
          bbcheckout = new BBCheckoutProcessor(checkoutFunctions(), checkoutModel.APIControllerName, checkoutModel.TokenId, '.adf__confirmation')

          bbcheckout.Configuration.Data.Key = checkoutModel.PublicKey
          bbcheckout.Configuration.TransactionType = checkoutModel.TransactionType
          bbcheckout.Configuration.Data.ClientAppName = checkoutModel.ClientAppName
          bbcheckout.Configuration.Data.MerchantAccountId = checkoutModel.MerchantAccountId
          bbcheckout.Configuration.Data.IsEmailRequired = checkoutModel.IsEmailRequired
          bbcheckout.Configuration.Data.IsNameVisible = checkoutModel.IsNameVisible
          bbcheckout.Configuration.Data.PrimaryColor = checkoutModel.PrimaryColor
          bbcheckout.Configuration.Data.SecondaryColor = checkoutModel.SecondaryColor
          bbcheckout.Configuration.Data.FontFamily = checkoutModel.FontFamily
          bbcheckout.Configuration.Data.UseCaptcha = checkoutModel.UseCaptcha
          bbcheckout.Configuration.WorkflowType = checkoutModel.WorkFlowType
          bbcheckout.Configuration.HandleBrowserClosing = checkoutModel.HandleBrowserClosing === true ? 'True' : 'False'
          bbcheckout.Configuration.APITokenID = checkoutModel.TokenId
          // You can add your own message to display on screen, after checkout pop-up close
          bbcheckout.Configuration.TempConfirmationHtml = 'Thank you for your contribution, please wait while we process your transaction.'
          bbcheckout.intializeCheckout()
        }

        function makePayment() {
          const donor = donation.Donor

          bbcheckout.Configuration.Data.Amount = Number(
            document.querySelector('.js-gift-amount-total').textContent.replace(/\$/, '').replace(/\,/g, '')
          ).toFixed(2)
          bbcheckout.Configuration.Data.BillingAddressCity = donor.Address.City
          bbcheckout.Configuration.Data.BillingAddressCountry = donor.Address.Country
          bbcheckout.Configuration.Data.BillingAddressLine = donor.Address.StreetAddress
          bbcheckout.Configuration.Data.BillingAddressPostCode = donor.Address.PostalCode
          bbcheckout.Configuration.Data.BillingAddressState = donor.Address.State
          bbcheckout.Configuration.Data.BillingAddressEmail = donor.EmailAddress
          bbcheckout.Configuration.Data.BillingAddressFirstName = donor.FirstName + ' ' + (donor.MiddleName ? donor.MiddleName : '')
          bbcheckout.Configuration.Data.BillingAddressLastName = donor.LastName
          bbcheckout.Configuration.Data.Cardholder = donor.FirstName + ' ' + donor.LastName
          bbcheckout.Configuration.Data.UseVisaCheckout = donation.Gift && !donation.Gift.Recurrence
          bbcheckout.Configuration.Data.UseMasterpass = donation.Gift && !donation.Gift.Recurrence
          bbcheckout.Configuration.Data.UseApplePay = donation.Gift && !donation.Gift.Recurrence
          bbcheckout.Configuration.TransactionType = bbcheckout.TransactionType.Card_Not_Present
          bbcheckout.Configuration.Data.CardToken = null
          if (donation.Gift && donation.Gift.Recurrence && !donation.Gift.Recurrence.ProcessNow) {
            bbcheckout.Configuration.Data.CardToken = checkoutModel.DataKey
            bbcheckout.Configuration.TransactionType = bbcheckout.TransactionType.Store_Card //Store card transactions
          } else if (donation.Gift && donation.Gift.Recurrence) {
            //Set CardToken value only in case of recurring gifts.
            bbcheckout.Configuration.Data.CardToken = checkoutModel.DataKey
          }
          //Set Donor Info so that it will be passed to finish the transaction at the end.
          donation.DonationSource = bbcheckout.Configuration.DonationSource.ADF
          donation.Type = bbcheckout.Configuration.TranType.Donation
          bbcheckout.DonorInfo = donation
          bbcheckout.openCheckout()
        }

        function processPayment() {
          function onSuccess(data) {
            removeSavedData()
            makePayment()
          }

          function onFail(errors) {
            console.log(errors)
          }

          ds.validateDonationRequest(donation, onSuccess, onFail)
        }

        function submitDonation() {
          function success(returnedDonation) {
            removeSavedData()
            if (returnedDonation.Donation.Gift.PaymentMethod === 1) {
              getConfirmation(returnedDonation.Donation.Id)
            }

            if (returnedDonation.Donation.Gift.PaymentMethod === 2) {
              window.location.href = window.location.href + (window.location.search === '' ? '?t=' : '&t=') + returnedDonation.Donation.Id
            }
          }

          function error(returnedErrors) {
            console.log(returnedErrors)
            enableNextButtons()
          }

          ds.createDonation(donation, success, error)
        }

        function createDonation() {
          disableNextButtons()
          saveForm()

          const isInternational = maybeInternational()
          const giftType = theForm.querySelector('[name="gift-type"]:checked').value
          const paymentMethod = parseInt(theForm.querySelector('[name="gift-payment-type"]:checked').value)
          let totalGiftAmount = 0

          donation = {
            // We can get some data directly from the form
            Donor: {
              Address: {
                City: isInternational ? document.getElementById('donor-city-intl').value : document.getElementById('donor-city').value,
                Country: isInternational ? getSelectedOptionText(document.getElementById('donor-country')) : 'United States',
                PostalCode: isInternational ? document.getElementById('donor-zip-intl').value : document.getElementById('donor-zip').value,
                State: isInternational
                  ? document.getElementById('donor-province-intl--text').value || getSelectedOptionText(document.getElementById('donor-province-intl--select'))
                  : getSelectedOptionValue(document.getElementById('donor-state')),
                StreetAddress: isInternational ? document.getElementById('donor-address-intl').value : document.getElementById('donor-address').value,
              },
              Title: getSelectedOptionValue(document.getElementById('donor-title')),
              FirstName: document.getElementById('donor-first-name').value,
              LastName: document.getElementById('donor-last-name').value,
              EmailAddress: document.getElementById('donor-email').value,
              Phone: document.getElementById('donor-phone').value,
            },
            Gift: {
              PaymentMethod: paymentMethod,
              Comments: document.getElementById('comments').value,
              IsCorporate: document.getElementById('is-corporate').checked,
            },
            Origin: {
              PageId: pageId,
              PartId: partId,
              ClientSitesID: ClientSitesID,
            },
            BBSPTemplateSitePageId: pageId,
            partId: partId,
            MerchantAccountId: processorGUID,
          }

          // The donation object needs a return URL for credit card payments
          if (donation.Gift.PaymentMethod === 0) {
            // donation.BBSPReturnUri = window.location.href;
            donation.BBSPReturnUri = 'https://www.google.com'
          }

          // Finder Number
          if (urlFinderNumber.value !== '') {
            donation.Gift.FinderNumber = urlFinderNumber.value
          }

          // Allow corporate gifts
          if (document.getElementById('is-corporate').checked) {
            donation.Donor.OrganizationName = document.getElementById('organization-name').value
          }

          // Is payment by credit card?
          function isCreditCard() {
            return donation.Gift.PaymentMethod === 0
          }

          // Is this a recurring gift?
          function isRecurring() {
            const giftType = theForm.querySelector('[name="gift-type"]:checked').value
            const isRecurring = giftType === '2' || giftType === '4'

            return isRecurring
          }

          // Gets the total of all gifts if needed
          function getTotalGiftAmount(val) {
            const numberOfInstallments = parseInt(numPaymentsField.value)

            return val * numberOfInstallments
          }

          // The data-guid attibute on fields gives us the GUIDs for submission
          function createAttribute(el, val) {
            let attribute = {}
            const concatAttr = el.getAttribute('data-concat')

            attribute.AttributeId = el.getAttribute('data-guid')
            attribute.Value = val ? val : el.getAttribute('data-attribute-value') || el.value
            if (concatAttr && document.getElementById(concatAttr)) {
              attribute.Value += ' ' + document.getElementById(concatAttr).value
            }
            return attribute
          }

          function getAttributes() {
            let attributes = []

            document.querySelectorAll('.is-attribute').forEach(function (el) {
              const isActive = el.type.toLowerCase() === 'checkbox' || el.type.toLowerCase() === 'radio' ? el.checked : el.value !== ''

              if (el.id.indexOf('tribute-type') > -1 && !isTribute.checked) {
                return false
              }

              if (isActive) {
                attributes.push(createAttribute(el))
              }
              return true
            })
            return attributes
          }

          function getTribute() {
            const isTributeChecked = isTribute.checked
            const hasAcknowledgee = document.getElementById('is-notify').checked
            let tribute = {}

            if (isTributeChecked) {
              tribute.TributeDefinition = {
                Description: document.getElementById('notify-display-name').value || 'New Memorial-Tribute',
                FirstName: document.getElementById('honoree-first-name').value,
                LastName: document.getElementById('honoree-last-name').value,
                Type: theForm.querySelector('[name="tribute-type"]:checked').value,
              }
              /*
               * 09-03-2021: JET4
               * These fields are required by the BBIS API but Not by this form.
               * Adding dummy text to ensure process can still continue when these
               * these values are not present.
               */

              if (hasAcknowledgee) {
                //Collect values
                let notifyAddress = document.getElementById('notify-address').value
                let notifyCity = document.getElementById('notify-city').value
                let notifyFirstName = document.getElementById('notify-first-name').value
                let notifyLastName = document.getElementById('notify-last-name').value
                let notifyZip = document.getElementById('notify-zip').value
                let notifyState = document.getElementById('notify-state').value

                //Ensure a value is present
                tribute.Acknowledgee = {
                  AddressLines: notifyAddress !== '' ? notifyAddress : 'UNKNOWN',
                  City: notifyCity !== '' ? notifyCity : 'UNKNOWN',
                  Country: 'USA',
                  FirstName: notifyFirstName !== '' ? notifyFirstName : 'UNKNOWN',
                  LastName: notifyLastName !== '' ? notifyLastName : 'UNKNOWN',
                  PostalCode: notifyZip !== '' ? notifyZip : 'UNKNOWN',
                  State: notifyState !== '' ? notifyState : 'UNKNOWN',
                }
              }
              return tribute
            }
            return null
          }

          function saveSpecifiedFund(fund) {
            localStorage.setItem('specifiedFund', fund)
          }

          function getSpecifiedFund(mygift) {
            let el = document.getElementById('specify-fund')

            if (el) {
              if (!donation.Gift.Attributes) {
                donation.Gift.Attributes = []
              }
              const specifiedFund = createAttribute(el, mygift.querySelector('.gift-label').innerText)
              donation.Gift.Attributes.push(specifiedFund)
              saveSpecifiedFund(specifiedFund.Value)
            }
            return document.getElementById('is-specify-fund').getAttribute('data-guid')
          }

          function getDesignations() {
            let designations = []

            document
              .getElementById('my-gifts')
              .querySelectorAll('.my-gift')
              .forEach(function (mygift) {
                let gift = {}
                const giftAmount = parseFloat(mygift.querySelector('.amount').value)

                gift.Amount = isRecurring() && !isCreditCard() ? getTotalGiftAmount(giftAmount) : giftAmount
                gift.DesignationId =
                  mygift.querySelector('.designation-specified').value === 'true' ? getSpecifiedFund(mygift) : mygift.querySelector('.designation-fund').value
                designations.push(gift)
                totalGiftAmount += gift.Amount
              })
            return designations
          }

          function getRecurrence() {
            const giftType = theForm.querySelector('[name="gift-type"]:checked').value
            let recurrence = {}

            if (isRecurring()) {
              recurrence = {
                DayOfMonth: new Date().getDate(),
                EndDate: new Date(document.getElementById('gift-end-date').value),
                StartDate: isCreditCard() ? new Date() : addMonths(new Date(), 1),
                Frequency: parseInt(giftType),
                ProcessNow: isCreditCard() ? true : false,
              }
              if (giftType === '4') {
                recurrence.Month = new Date().getMonth() + 1
              }
              return recurrence
            }
            return null
          }

          function getPledgeInstallments() {
            let pledgeInstallments = {}

            if (isRecurring()) {
              const numberOfInstallments = parseInt(numPaymentsField.value)

              if (numberOfInstallments) {
                const installmentAmount = parseFloat(ds.getRecurringGiftInstallmentAmount(totalGiftAmount, numberOfInstallments))

                pledgeInstallments = {
                  NumberOfInstallments: numberOfInstallments,
                  InstallmentAmount: installmentAmount,
                }
              }
            }
            return pledgeInstallments
          }

          let attributes = getAttributes()
          if (attributes.length > 0) {
            donation.Gift.Attributes = attributes
          }

          let tribute = getTribute()
          if (tribute) {
            donation.Gift.Tribute = tribute
          }

          let designations = getDesignations()
          if (designations.length > 0) {
            donation.Gift.Designations = designations
          }

          let recurrence = getRecurrence()
          if (recurrence) {
            donation.Gift.Recurrence = recurrence
          }

          if (paymentMethod !== 0 && giftType !== '') {
            let pledgeInstallments = getPledgeInstallments()
            if (pledgeInstallments) {
              donation.Gift.PledgeInstallment = pledgeInstallments
            }
          }

          if (paymentMethod === 2) {
            donation.Gift.DirectDebitInformation = {}
            donation.Gift.DirectDebitInformation.FinancialInstitution =
              'Baylor Payroll - ' + theForm.querySelector('[name="baylor-payroll-schedule"]:checked').value
            donation.Gift.DirectDebitInformation.BranchName = ''
            donation.Gift.DirectDebitInformation.AccountHolderName =
              document.getElementById('donor-first-name').value + ' ' + document.getElementById('donor-last-name').value
            donation.Gift.DirectDebitInformation.BankingType = document.getElementById('ddlBankingSystemType').value
            donation.Gift.DirectDebitInformation.RoutingNumber = document.getElementById('txtRoutingNumber').value
            donation.Gift.DirectDebitInformation.AccountNumber = document.getElementById('txtAccountNumber').value
            donation.Gift.DirectDebitInformation.AccountType = document.getElementById('ddlAccountType').value
          }

          if (appealID !== '') {
            donation.Origin.AppealId = appealID
          }

          if (paymentMethod === 0) {
            processPayment()
          } else {
            submitDonation()
          }
        }

        function validation() {
          // Step 1 validation
          const fv1 = FormValidation.formValidation(formSteps[0], {
            fields: {
              'finder-number': {
                validators: {
                  callback: {
                    message: 'Please enter your finder number',
                    callback: function (input) {
                      return searchParams.get('showfinder') === '1' ? input.value !== '' : true
                    },
                  },
                },
              },
              'gift-amount': {
                validators: {
                  notEmpty: {
                    message: 'Please select a gift amount',
                  },
                },
              },
              'gift-amount-other': {
                validators: {
                  notEmpty: {
                    message: 'Please enter a gift amount',
                  },
                },
              },
              'fund-select-academics': {
                validators: {
                  callback: {
                    message: 'Please select a fund above before continuing.',
                    callback: function (input) {
                      return isFundSelected('designation-1', input)
                    },
                  },
                },
              },
              'fund-select-campus-life': {
                validators: {
                  callback: {
                    message: 'Please select a fund above before continuing.',
                    callback: function (input) {
                      return isFundSelected('designation-2', input)
                    },
                  },
                },
              },
              'fund-select-featured': {
                validators: {
                  callback: {
                    message: 'Please select a fund above before continuing.',
                    callback: function (input) {
                      return isFundSelected('designation-3', input)
                    },
                  },
                },
              },
            },
            plugins: {
              trigger: new FormValidation.plugins.Trigger({
                event: {
                  'designation-select': 'change',
                },
              }),
              bulma: new FormValidation.plugins.Bulma({
                defaultMessageContainer: false,
                rowSelector: function (field, ele) {
                  return field === 'gift-amount-other' ? '.columns' : '.field'
                },
              }),
              aria: new FormValidation.plugins.Aria(),
              message: new FormValidation.plugins.Message({
                clazz: 'is-danger',
                container: function (field, el) {
                  if (hasClass(el, 'unit-select') || hasClass(el, 'area-select') || hasClass(el, 'fund-select')) {
                    return document.getElementById('designation-select-message')
                  } else if (field === 'gift-amount-other') {
                    return document.getElementById('gift-amount-message')
                  } else if (field === 'finder-number') {
                    return document.getElementById('finder-number-message')
                  } else {
                    return FormValidation.utils.closest(el, '.field')
                  }
                },
              }),
              excluded: new FormValidation.plugins.Excluded({
                excluded: function (field, el, els) {
                  const selectedAmount = theForm.querySelector('[name="gift-amount"]:checked')
                  const giftAmount = selectedAmount ? selectedAmount.value : ''

                  return (field === 'gift-amount-other' && giftAmount !== 'other') || (field === 'gift-amount' && giftAmount === 'other')
                },
              }),
            },
          }).on('core.form.valid', function () {
            const activeCategorySelect =
              getSelectedOptionValue(document.getElementById('designation-search-results')) !== ''
                ? document.getElementById('designation-search-results')
                : theForm.querySelector('.tab-content.is-active .fund-select')
            const redirectURL = getSelectedOptionAttribute(activeCategorySelect, 'data-redirectURL')

            if (redirectURL) {
              window.location.assign(redirectURL)
            } else {
              if (editingGift) {
                updateGiftRow()
              } else if (isPreSelectedFundOnly) {
                const designation = getPreSelectedData()
                const amount = getGiftAmount()

                if (designation && amount) {
                  addPreSelectedDonation(designation.lookupId, amount, designation.editStatus, designation.removeStatus)
                }
                isPreSelectedFundOnly = false
                hidePreSelectedDesignation()
              } else {
                addGiftRow()
              }
              clearGiftFields()
              displayNextStep()
              saveForm()
            }
          })

          Array.from(getAll('[name="gift-amount"]')).forEach(function (el) {
            el.addEventListener('click', function (e) {
              fv1.revalidateField('gift-amount-other')
            })
          })

          // Step 2 validation
          const fv2 = FormValidation.formValidation(formSteps[1], {
            fields: {
              'honoree-name': {
                validators: {
                  callback: {
                    message: '(Required)',
                    callback: function (input) {
                      const honorIsChecked = theForm.querySelector('[name="is-tribute"]:checked')

                      return honorIsChecked ? input.value !== '' : true
                    },
                  },
                },
              },
              'gift-num-payments': {
                validators: {
                  callback: {
                    message: '(Required)',
                    callback: function (input) {
                      const giftType = theForm.querySelector('[name="gift-type"]:checked')
                      const isPledge = giftType.value === '2' || giftType === '4' ? true : false

                      return isPledge ? input.value !== '' && !isNaN(input.value) && parseInt(input.value) > 0 : true
                    },
                  },
                },
              },
            },
            plugins: {
              trigger: new FormValidation.plugins.Trigger(),
              bulma: new FormValidation.plugins.Bulma(),
              aria: new FormValidation.plugins.Aria(),
            },
          }).on('core.form.valid', function () {
            updateReviewInfo()
            displayNextStep()
            saveForm()
          })

          // Step 3 validation
          const fv3 = FormValidation.formValidation(formSteps[2], {
            fields: {
              'donor-first-name': {
                validators: {
                  notEmpty: {
                    message: '(Required)',
                  },
                  regexp: {
                    regexp: /^(a-z|A-Z)*[^\d#$%^&*()']*$/, // Non all numeric
                    message: '(Cannot contain special chars or numbers.)',
                  },
                  stringLength: {
                    max: 50,
                    message: '(Cannot be more than 50 chars.)',
                  },
                },
              },
              'donor-last-name': {
                validators: {
                  notEmpty: {
                    message: '(Required)',
                  },
                  regexp: {
                    regexp: /^(a-z|A-Z)*[^\d#$%^&*()']*$/, // Non all numeric
                    message: '(Cannot contain special chars or numbers.)',
                  },
                  stringLength: {
                    max: 100,
                    message: '(Cannot be more than 100 chars.)',
                  },
                },
              },
              'donor-phone': {
                validators: {
                  notEmpty: {
                    message: '(Required)',
                  },
                  regexp: {
                    regexp: /^[^#$%^&*.']*$/, // Non all numeric
                    message: '(Cannot contain special chars.)',
                  },
                },
              },
              'organization-name': {
                validators: {
                  callback: {
                    message: '(Required)',
                    callback: function (input) {
                      return document.getElementById('is-corporate').checked ? input.value !== '' : true
                    },
                  },
                },
              },
              'bear-id': {
                validators: {
                  callback: {
                    message: '(Required)',
                    callback: function (input) {
                      const payrollIsChecked = theForm.querySelector('.is-collapse-control[name="gift-payment-type"]:checked')

                      return payrollIsChecked ? input.value !== '' : true
                    },
                  },
                  regexp: {
                    regexp: /^(?=.*?\d*)(?=.*?[\D])[\D\d]+$/, // Non all numeric
                    message: '(Your Bear ID should not be an all numeric value)',
                  },
                },
              },
              'bear-password': {
                validators: {
                  callback: {
                    message: '(Required)',
                    callback: function (input) {
                      const payrollIsChecked = theForm.querySelector('.is-collapse-control[name="gift-payment-type"]:checked')

                      return payrollIsChecked ? input.value !== '' : true
                    },
                  },
                },
              },
              'baylor-payroll-schedule': {
                validators: {
                  callback: {
                    message: '(Required)',
                    callback: function (input) {
                      const payrollIsChecked = theForm.querySelector('.is-collapse-control[name="gift-payment-type"]:checked')

                      return payrollIsChecked ? isInputChecked(input) : true
                    },
                  },
                },
              },
            },
            plugins: {
              trigger: new FormValidation.plugins.Trigger(),
              declarative: new FormValidation.plugins.Declarative({
                html5Input: true,
              }),
              bulma: new FormValidation.plugins.Bulma(),
              excluded: new FormValidation.plugins.Excluded(),
              aria: new FormValidation.plugins.Aria(),
            },
          }).on('core.form.valid', function () {
            updateReviewInfo()
            displayNextStep()
            saveForm()
          })

          if (nextButtons.length > 0) {
            nextButtons.forEach(function (el) {
              el.addEventListener('click', function () {
                switch (currentStep) {
                  case 0:
                    fv1.validate()
                    break
                  case 1:
                    fv2.validate()
                    break
                  case 2:
                    fv3.validate()
                    break
                  case 3:
                    createDonation()
                    break
                  default:
                    break
                }
              })
            })
          }
        }
        validation()

        function isFundSelected(category, input) {
          return (
            !document.getElementById(category).checked ||
            input.value !== '' ||
            getSelectedOptionValue(document.getElementById('designation-search-results')) !== '' ||
            (document.getElementById('is-specify-fund').checked && document.getElementById('specify-fund').value !== '') ||
            isPreSelectedFundOnly
          )
        }

        function isInputChecked(input) {
          let checkedInput = input.elements.filter(function (obj) {
            return obj.checked
          })

          return checkedInput.length > 0
        }

        // Handlers
        window.addEventListener(
          'keydown',
          function (e) {
            if (e.keyIdentifier == 'U+000A' || e.keyIdentifier == 'Enter' || e.keyCode == 13) {
              if (
                e.target.nodeName == 'INPUT' &&
                (e.target.type === 'text' || e.target.type === 'search' || e.target.type === 'email' || e.target.type === 'tel')
              ) {
                e.preventDefault()
                return false
              }
            }
          },
          true
        )

        function createDropdown(field, dropdown, els) {
          if (field && dropdown) {
            clearOptions(dropdown)
            els.forEach(function (el) {
              return typeof el === 'string' ? createOption(dropdown, el) : createDesignationOption(dropdown, el)
            })
            showElement(field)
          }
        }

        function showFunds(dropdown) {
          let nextField
          let designationType = designationAreas[dropdown.value] ? (designationAreas[dropdown.value].length > 0 ? 'area' : '') : ''

          designationType = designationType || (designationFunds[dropdown.value] ? (designationFunds[dropdown.value].length > 0 ? 'fund' : '') : '')

          if (hasClass(dropdown, 'unit-select')) {
            selectedUnit = getSelectedOptionValue(dropdown)
          }

          if (designationType === 'area' && !hasClass(dropdown, 'area-select')) {
            hideFunds()
            nextField = getClosestEl(dropdown, '.field').nextElementSibling
            createDropdown(nextField, nextField.querySelector('select'), designationAreas[dropdown.value])
          } else if (designationType === 'fund' || hasClass(dropdown, 'area-select')) {
            if (designationAreas[selectedUnit]) {
              if (designationAreas[selectedUnit].length > 0) {
                hideFunds('fund')
              } else {
                hideFunds('area')
              }
            }
            nextField = getClosestEl(dropdown, '.field').nextElementSibling.nextElementSibling || getClosestEl(dropdown, '.field').nextElementSibling
            createDropdown(nextField, nextField.querySelector('select'), designationFunds[dropdown.value])
          }
        }

        function hideFunds(type) {
          const allNotFeaturedSubFunds = Array.prototype.slice
            .call(giftAreas)
            .concat(Array.prototype.slice.call(giftFunds))
            .filter(function (el) {
              return el.id !== 'fund-select-featured'
            })

          const notFeaturedFunds = Array.prototype.slice.call(giftFunds).filter(function (el) {
            return el.id !== 'fund-select-featured'
          })

          const els = type ? (type === 'area' ? giftAreas : notFeaturedFunds) : allNotFeaturedSubFunds

          els.forEach(function (el) {
            hideElement(getClosestEl(el, '.field'))
            selectOption(el, '')
          })
        }

        function clearUnits() {
          giftUnits.forEach(function (el) {
            selectOption(el, '')
          })
        }

        function clearSpecifiedFund() {
          document.getElementById('specify-fund').value = ''
          if (document.getElementById('is-specify-fund').checked) {
            triggerEvent(document.getElementById('is-specify-fund-trigger'), 'click')
          }
          hideElement(document.getElementById('specify-fund-checkbox'))
        }

        function hideGiftLevels() {
          const els = getAll('.gift-levels')

          if (els.length > 0) {
            els.forEach(function (el) {
              hideElement(el)
            })
          }
        }

        function handleFundChange(e) {
          if (this.value !== '') {
            showFunds(this)
          } else {
            if (hasClass(this, 'unit-select')) {
              hideFunds('area')
            } else {
              hideFunds()
            }
          }
        }

        if (designationButtons.length > 0) {
          const notFeatured = Array.prototype.slice.call(designationButtons).filter(function (el) {
            return el.value !== 'Featured'
          })

          notFeatured.forEach(function (el) {
            el.addEventListener('click', function () {
              hideFunds()
            })
          })
        }

        if (giftUnits.length > 0 && giftAreas.length > 0) {
          els = Array.prototype.slice.call(giftUnits).concat(Array.prototype.slice.call(giftAreas))
          els.forEach(function (el) {
            el.addEventListener('change', handleFundChange)
          })
        }

        function addGiftLevelRow(level, index) {
          let row = document.createElement('li')

          row.innerText = '$' + level[0] + '+ ' + level[1]
          if (level[2] !== '') {
            let infoLink = document.createElement('a')

            infoLink.setAttribute('href', level[2])
            infoLink.setAttribute('target', '_blank')
            addClass(infoLink, 'fa fa-info-circle')
            row.appendChild(infoLink)
          }
          return row
        }

        function displayGiftLevels(levels) {
          const giftLevels = document.getElementById('gift-levels')

          if (giftLevels) {
            clearElement(giftLevels)
            levels.forEach(function (level, index) {
              giftLevels.appendChild(addGiftLevelRow(level, index))
            })
          }
        }

        function maybeGiftLevels(guid) {
          const giftLevels = theForm.querySelector('.gift-levels')
          const fund = allDesignations.filter(function (obj) {
            return obj.guid === guid && Array.isArray(obj.levels)
          })

          if (giftLevels) {
            if (fund.length === 1) {
              displayGiftLevels(fund[0].levels)
              showElement(giftLevels)
            } else {
              hideElement(giftLevels)
            }
          }
        }

        els = Array.prototype.slice.call(giftFunds).concat(Array.prototype.slice.call(searchFunds))
        if (els.length > 0) {
          els.forEach(function (el) {
            el.addEventListener('change', function () {
              maybeGiftLevels(getSelectedOptionValue(this))
            })
          })
        }

        if (giftAmountOtherButton && giftAmountOther) {
          giftAmountOtherButton.addEventListener('click', function () {
            setTimeout(function () {
              giftAmountOther.focus()
            }, 100)
          })
        }

        function isCreditCard() {
          return parseInt(theForm.querySelector('[name="gift-payment-type"]:checked').value) === 0
        }

        function updateRecurrenceTotalAmount(numPayments) {
          const giftAmount = theForm.querySelector('.js-gift-amount-total').innerText
          const giftAmountRecurrence = giftAmount * numPayments

          const els = getAll('.js-gift-amount-total-recurring')
          if (els.length > 0) {
            els.forEach(function (el) {
              el.innerText = '$' + numToFloat(giftAmountRecurrence, 2)
            })
          }
        }

        function updateRecurrenceDates(numPayments) {
          const today = new Date()
          const startDate = new Date()
          const endDate =
            giftFrequency === 'monthly'
              ? new Date(startDate.setMonth(startDate.getMonth() + numPayments))
              : new Date(startDate.setFullYear(startDate.getFullYear() + numPayments))
          const localeEndDate = endDate.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })
          const els = getAll('.js-gift-end-date')

          document.getElementById('gift-start-date').value = today
          document.getElementById('gift-end-date').value = endDate

          if (els.length > 0) {
            els.forEach(function (el) {
              el.innerText = localeEndDate
            })
          }
        }

        function setRecurrenceFields() {
          const numPayments = parseInt(numPaymentsField.value)

          if (!isNaN(numPayments) && numPayments > 0) {
            if (isCreditCard()) {
              updateRecurrenceDates(numPayments - 1)
            } else {
              updateRecurrenceDates(numPayments)
            }
            updateRecurrenceTotalAmount(numPayments)
          }
        }

        numPaymentsField.addEventListener('keyup', setRecurrenceFields)

        function updateFrequencyText() {
          const root = document.getElementById('recurring-gift-fields')
          let els = root.querySelectorAll('.js-gift-frequency')

          if (els.length > 0) {
            els.forEach(function (el) {
              el.innerText = giftFrequency
            })
          }

          els = root.querySelectorAll('.js-gift-amount-total')
          if (els.length > 0) {
            els.forEach(function (el) {
              el.innerText = theForm.querySelector('.js-gift-amount-total').innerText
            })
          }
        }

        function enableMemorialTribute() {
          const el = document.getElementById('gift-memory-tribute')
          const helpText = el.querySelector('.help')
          const tributeFields = document.getElementById('tribute-fields')

          if (el) {
            el.disabled = false
            if (helpText) {
              hideElement(helpText)
            }
            if (tributeFields) {
              showElement(tributeFields)
            }
          }
        }

        function collapseControlFilter(el) {
          if (el.nodeName.toLowerCase() === 'a' && el.dataset) {
            return (el.dataset['action'] = 'collapse')
          }
          return false
        }

        function clearTributeFields() {
          const tributeFields = document.getElementById('tribute-fields')

          if (tributeFields) {
            getAll('input:not([type="checkbox"]), select', tributeFields).forEach(function (el) {
              el.value = ''
            })
            getAll('input[type="checkbox"], input[type="radio"]', tributeFields).forEach(function (el) {
              if (el.id === 'is-notify' && el.checked) {
                const notifyControl = tributeFields.querySelector('a[href="#tribute-fields__notify"]')
                if (notifyControl) {
                  notifyControl.click()
                }
              }
              el.checked = false
            })
          }
        }

        function clearNotifyFields() {
          const notifyFields = document.getElementById('tribute-fields__notify')

          if (notifyFields) {
            getAll('input:not([type="checkbox"]), select', notifyFields).forEach(function (el) {
              el.value = ''
            })
          }
        }

        function disableMemorialTribute() {
          const el = document.getElementById('gift-memory-tribute')
          const helpText = el.querySelector('.help')
          const tributeToggle = document.getElementById('tribute-toggle')
          const tributeFields = document.getElementById('tribute-fields')

          if (el) {
            if (isTribute) {
              if (isTribute.checked) {
                if (tributeToggle) {
                  tributeToggle.click()
                }
              }
            }
            el.disabled = true
            if (helpText) {
              showElement(helpText)
            }
            clearTributeFields()
          }
        }

        function toggleMemorialTribute(el) {
          if (el.value === '') {
            enableMemorialTribute()
          } else {
            disableMemorialTribute()
          }
        }

        el = document.getElementById('is-tribute')
        if (el) {
          el.addEventListener('click', function () {
            if (!this.checked) {
              clearTributeFields()
            }
          })
        }

        el = document.getElementById('is-notify')
        if (el) {
          el.addEventListener('click', function () {
            if (!this.checked) {
              clearNotifyFields()
            }
          })
        }

        if (paymentButtons.length > 0) {
          paymentButtons.forEach(function (el) {
            el.addEventListener('click', function () {
              const existingPledge = document.getElementById('is-pledge-field')

              if (this.value === '1') {
                hideElement(existingPledge)
              } else {
                showElement(existingPledge)
              }
              if (theForm.querySelector('[name="gift-type"]:checked').value !== '') {
                setRecurrenceFields()
                updateReviewInfo()
              }
            })
          })
        }

        function setFrequency(el) {
          if (el) {
            switch (el.value) {
              case '':
                giftFrequency = ''
                break
              case '2':
                giftFrequency = 'monthly'
                break
              case '4':
                giftFrequency = 'annual'
                break
            }
          }
        }

        if (frequencyButtons.length > 0) {
          frequencyButtons.forEach(function (el) {
            el.addEventListener('click', function () {
              setFrequency(el)
              toggleMemorialTribute(el)
              updateFrequencyText()
              setRecurrenceFields()
              updateReviewInfo()
            })
          })
        }

        if (growingFields.length > 0) {
          growingFields.forEach(function (el) {
            el.addEventListener('keyup', function () {
              const measureText = el.nextElementSibling
              measureText.innerText = this.value
              this.style.width = measureText.offsetWidth + 'px'
            })
          })
        }

        if (addButtons.length > 0) {
          addButtons.forEach(function (el) {
            el.addEventListener('click', function () {
              editingGift = false
              isAnotherGift = true
              displayPreviousStep(this)
            })
          })
        }

        if (nextButtons.length > 0) {
          enableNextButtons()
        }

        if (prevButtons.length > 0) {
          prevButtons.forEach(function (el) {
            el.addEventListener('click', displayPreviousStep)
          })
        }

        if (cancelButtons.length > 0) {
          cancelButtons.forEach(function (el) {
            el.addEventListener('click', cancelStep)
          })
        }

        function showHideBanner() {
          const banner = document.querySelector('.baylor__banner')

          if (banner) {
            if (currentStep === 0) {
              showElement(banner)
            } else {
              hideElement(banner)
            }
          }
        }

        function initGiftButtons() {
          editButtons = getAll('.step-action-edit')
          removeButtons = getAll('.step-action-remove')

          if (editButtons.length > 0) {
            editButtons.forEach(function (el) {
              el.addEventListener('click', editGift)
            })
          }

          if (removeButtons.length > 0) {
            removeButtons.forEach(function (el) {
              el.addEventListener('click', removeGift)
            })
          }
        }

        function updateButtonText() {
          switch (currentStep) {
            case 0:
              return 'Next: Review Your Gift'
            case 1:
              return 'Next: Your Info'
            case 2:
              return 'Next: Confirm'
            case 3:
              return isCreditCard() ? 'Make Payment' : 'Submit Your Gift'
          }
        }

        function enableNextButtons() {
          nextButtons.forEach(function (el) {
            el.disabled = false
            removeClass(el, 'is-disabled')
          })
        }

        function disableNextButtons() {
          nextButtons.forEach(function (el) {
            el.disabled = true
            addClass(el, 'is-disabled')
          })
        }

        function updateNextButtons() {
          nextButtons.forEach(function (el) {
            const buttonText = el.querySelector('.button-text') ? el.querySelector('.button-text') : el
            buttonText.innerText = updateButtonText()
          })
        }

        function updatePreviousButtons() {
          prevButtons.forEach(function (el) {
            el.disabled = currentStep !== 2 && !el.hasAttribute('data-step-rel') ? true : false
          })
        }

        function updateCancelButtons() {
          cancelButtons.forEach(function (el) {
            el.disabled = (isAnotherGift || editingGift) && currentStep === 0 ? false : true
          })
        }

        function updateStepMarkers() {
          stepMarkers.forEach(function (el) {
            deactivateElement(el)
            removeClass(el, 'is-completed')
          })

          for (let i = 0; i < currentStep; i++) {
            addClass(stepMarkers[i], 'is-completed')
          }
          activateElement(stepMarkers[currentStep])
        }

        function updateStepMarkerText() {
          stepMarkers.forEach(function (el) {
            const normalText = el.querySelector('.step-title:not(.step-title--completed)')
            const completedText = el.querySelector('.step-title--completed')

            if (normalText && completedText) {
              if (hasClass(el, 'is-completed')) {
                hideElement(normalText)
                showElement(completedText)
              } else {
                hideElement(completedText)
                showElement(normalText)
              }
            }
          })
        }

        function displayStep() {
          formSteps.forEach(function (el) {
            removeClass(el, 'js-step-active')
          })
          addClass(formSteps[currentStep], 'js-step-active')

          showHideBanner()
          updateNextButtons()
          updatePreviousButtons()
          updateCancelButtons()
          updateStepMarkers()
          updateStepMarkerText()
          initGiftButtons()
          handleAmountChange()
          goToTop()
          isAnotherGift = false
        }

        function getCurrentStep(el) {
          currentStep = el.getAttribute('data-step-rel') ? parseInt(el.getAttribute('data-step-rel')) : currentStep
        }

        function displayNextStep() {
          currentStep++
          displayStep()
        }

        function displayPreviousStep(arg) {
          currentStep--
          getCurrentStep(arg.currentTarget ? arg.currentTarget : arg)
          displayStep()
        }

        function cancelStep() {
          getCurrentStep(this)
          if (currentStep === 1) {
            clearGiftFields()
          }
          displayStep()
        }

        function restoreDesignationSearch(gift) {
          let el = document.getElementById('designation-search')

          el.value = gift.querySelector('.gift-label').innerText
          triggerEvent(el, 'keyup')
          setTimeout(function () {
            document.getElementById('designation-search-results').value = gift.querySelector('.designation-fund').value
          }, 1000)
        }

        function restoreSpecifiedDesignation(gift) {
          showElement(document.getElementById('specify-fund-checkbox'))
          triggerEvent(document.getElementById('is-specify-fund-trigger'), 'click')
          document.getElementById('specify-fund').value = gift.querySelector('.gift-label').innerText
        }

        function restoreDropdown(dropdown, val) {
          selectOption(dropdown, val)
          triggerEvent(dropdown, 'change')
        }

        function restoreDropdowns(gift, selectedDesignation) {
          if (gift.querySelector('.designation-unit').value !== '') {
            restoreDropdown(selectedDesignation.querySelector('.unit-select'), gift.querySelector('.designation-unit').value)
          }
          if (gift.querySelector('.designation-area').value !== '') {
            restoreDropdown(selectedDesignation.querySelector('.area-select'), gift.querySelector('.designation-area').value)
          }
          if (gift.querySelector('.designation-fund').value !== '') {
            restoreDropdown(selectedDesignation.querySelector('.fund-select'), gift.querySelector('.designation-fund').value)
          }
        }

        function selectDesignation(gift) {
          const selectedDesignation = filter.call(designationButtons, function (el) {
            return el.value === gift.querySelector('.designation').value
          })

          if (selectedDesignation.length > 0) {
            selectedDesignation[0].checked = true
            triggerEvent(selectedDesignation[0], 'click')
            return selectedDesignation[0].getAttribute('data-tab')
          } else {
            return null
          }
        }

        function getGiftIndex(gift) {
          return gift.getAttribute('data-row-index')
        }

        function tryMatchingAmount(amount) {
          const amountButtons = getAll('[name="gift-amount"]')
          const matchedAmount = filter.call(amountButtons, function (el) {
            return el.value === amount
          })

          if (matchedAmount[0]) {
            matchedAmount[0].checked = true
            triggerEvent(matchedAmount[0], 'click')
            return true
          }
        }

        function setOtherAmount(amount) {
          document.getElementById('gift-amount-other-button').checked = true
          triggerEvent(document.getElementById('gift-amount-other-button'), 'click')
          document.getElementById('gift-amount-other').value = amount
          setTimeout(function () {
            document.getElementById('gift-amount-other').blur()
          }, 100)
        }

        function restoreAmount(gift) {
          let amount = gift.querySelector('.amount').value

          amount = String(Number.isInteger(Number(amount)) ? parseInt(amount) : amount)
          if (tryMatchingAmount(amount)) {
            return
          } else {
            setOtherAmount(amount)
          }
        }

        function restoreGift(el) {
          const gift = getClosestEl(el, '.my-gift')
          const selectedDesignation = document.getElementById(selectDesignation(gift))

          giftIndex = getGiftIndex(gift)
          if (gift.querySelector('.designation-search').value === 'true' || !selectedDesignation) {
            restoreDesignationSearch(gift)
          } else if (gift.querySelector('.designation-specified').value === 'true') {
            restoreSpecifiedDesignation(gift)
          } else {
            restoreDropdowns(gift, selectedDesignation)
          }
          restoreAmount(gift)
        }

        function editGift() {
          editingGift = true
          getCurrentStep(this)
          displayStep()
          restoreGift(this)
        }

        function updateGiftAmounts(target) {
          const giftIndex = getClosestEl(target, '.my-gift').getAttribute('data-row-index')
          const els = getAll('[data-row-index="' + giftIndex + '"]')

          if (els.length > 0) {
            els.forEach(function (el) {
              el.querySelector('.amount').value = target.value
            })
          }
        }

        function updateGiftAmountTotal(target = theForm.querySelector('.amount')) {
          const myGifts = getClosestEl(target, '.my-gifts').querySelectorAll('.my-gift')
          const giftAmountTotals = getAll('.js-gift-amount-total')
          let giftAmountTotal = 0

          if (myGifts.length > 0) {
            myGifts.forEach(function (el) {
              giftAmountTotal += Number(el.querySelector('.amount').value)
            })
          }

          if (giftAmountTotals.length > 0) {
            giftAmountTotals.forEach(function (el) {
              el.innerText = String(numToFloat(giftAmountTotal, 2))
            })
          }
        }

        function updateGiftAmount() {
          this.value = isNaN(parseFloat(this.value)) ? '' : numToFloat(parseFloat(this.value), 2)
          updateGiftAmounts(this)
          updateGiftAmountTotal(this)
          setRecurrenceFields()
          updateReviewInfo()
        }

        function handleAmountChange() {
          const giftAmounts = getAll('.amount')

          if (giftAmounts.length > 0) {
            giftAmounts.forEach(function (el) {
              el.addEventListener('change', updateGiftAmount)
            })
          }
        }

        function removeGift() {
          const thisButton = this
          const rowIndex = getClosestEl(thisButton, '.my-gift').getAttribute('data-row-index')

          getAll('[data-row-index="' + rowIndex + '"]').forEach(function (el) {
            el.remove()
          })
          let gifts = getAll('.my-gift')
          if (gifts.length > 0) {
            const giftRowsContainers = getAll('.my-gifts')
            if (giftRowsContainers.length > 0) {
              giftRowIndex = gifts.length / giftRowsContainers.length
              giftRowsContainers.forEach(function (el) {
                gifts = el.querySelectorAll('.my-gift')
                gifts.forEach(function (el, i) {
                  el.setAttribute('data-row-index', i + 1)
                })
              })
            }
            updateGiftAmountTotal()
            setRecurrenceFields()
            updateReviewInfo()
          } else {
            giftRowIndex = 0
            displayPreviousStep(thisButton)
          }
        }

        function getGiftAmount() {
          const selectedAmount = theForm.querySelector('[name="gift-amount"]:checked').value

          return Number(selectedAmount !== 'other' ? selectedAmount : document.getElementById('gift-amount-other').value)
        }

        function getGift() {
          const designation = theForm.querySelector('.gift-designation .tab-content.is-active')
          const selectedAmount = theForm.querySelector('[name="gift-amount"]:checked').value
          const gift = {
            searchResult: document.getElementById('designation-search-results'),
            isSearchResult: getSelectedOptionValue(document.getElementById('designation-search-results')) !== '',
            isSpecifiedFund: document.getElementById('is-specify-fund').checked && document.getElementById('specify-fund').value !== '',
            giftUnit: designation.querySelector('.unit-select') ? designation.querySelector('.unit-select') : null,
            giftArea: designation.querySelector('.area-select') ? designation.querySelector('.area-select') : null,
            giftFund: designation.querySelector('.fund-select') ? designation.querySelector('.fund-select') : null,
            giftAmount: getGiftAmount(),
          }
          return gift
        }

        function createGift(el, gift, index) {
          el.querySelector('.gift-label').innerText = gift.isSearchResult
            ? getSelectedOptionText(gift.searchResult)
            : gift.isSpecifiedFund
            ? document.getElementById('specify-fund').value
            : getSelectedOptionText(gift.giftFund)
          el.querySelector('.amount').value = numToFloat(gift.giftAmount, 2)
          el.querySelector('.amount').name = 'amount-' + giftRowIndex
          el.querySelector('.designation').value = theForm.querySelector('[name="designation"]:checked').value
          el.querySelector('.designation').name = 'designation-' + giftRowIndex
          el.querySelector('.designation-unit').value =
            gift.isSpecifiedFund || gift.isSearchResult ? '' : gift.giftUnit ? getSelectedOptionValue(gift.giftUnit) : ''
          el.querySelector('.designation-unit').name = 'designation-unit-' + giftRowIndex
          el.querySelector('.designation-area').value =
            gift.isSpecifiedFund || gift.isSearchResult ? '' : gift.giftArea ? getSelectedOptionValue(gift.giftArea) : ''
          el.querySelector('.designation-area').name = 'designation-area-' + giftRowIndex
          el.querySelector('.designation-fund').value = gift.isSearchResult
            ? getSelectedOptionValue(gift.searchResult)
            : gift.giftFund
            ? getSelectedOptionValue(gift.giftFund)
            : ''
          el.querySelector('.designation-fund').name = 'designation-fund-' + giftRowIndex
          el.querySelector('.designation-search').value = gift.isSearchResult && !gift.isSpecifiedFund ? true : false
          el.querySelector('.designation-search').name = 'designation-search-' + giftRowIndex
          el.querySelector('.designation-specified').value = gift.isSpecifiedFund ? true : false
          el.querySelector('.designation-specified').name = 'designation-specified-' + giftRowIndex
          return el
        }

        function updateGiftRow() {
          const gift = getGift()
          const giftRows = getAll('[data-row-index="' + giftIndex + '"]')

          if (giftRows.length > 0) {
            giftRows.forEach(function (el, index) {
              el = createGift(el, gift, index)
            })
            updateGiftAmountTotal()
            setRecurrenceFields()
            updateReviewInfo()
          }
        }

        function getGiftRowTemplate() {
          let template = document.getElementById('my-gift-template').cloneNode(true)

          template.removeAttribute('id')
          template.removeAttribute('style')
          template.setAttribute('data-row-index', giftRowIndex)
          return template
        }

        function addGiftRow() {
          const gift = getGift()
          const giftRowsContainers = getAll('.my-gifts')

          giftRowIndex++

          if (giftRowsContainers.length > 0) {
            giftRowsContainers.forEach(function (el) {
              let clone = getGiftRowTemplate()

              clone = createGift(clone, gift)
              el.insertBefore(clone, el.querySelector('.my-gift-add'))
            })
            updateGiftAmountTotal()
            setRecurrenceFields()
            updateReviewInfo()
          }
        }

        function addPreSelectedGiftRow(preSelectedGift) {
          const giftFundCategory = fundCategories[preSelectedGift.designation.categories[0]] ? fundCategories[preSelectedGift.designation.categories[0]] : ''
          const giftFundUnit = preSelectedGift.designation.categories[1] ? preSelectedGift.designation.categories[1] : ''
          const giftFundArea = preSelectedGift.designation.categories[2] ? preSelectedGift.designation.categories[2] : ''
          const giftFundId = preSelectedGift.designation.guid
          const giftFundText = preSelectedGift.designation.name
          const giftAmount = numToFloat(preSelectedGift.amount, 2)
          const editStatus = preSelectedGift.edit
          const removeStatus = preSelectedGift.remove
          const giftRowsContainers = getAll('.my-gifts')

          giftRowIndex++

          if (giftRowsContainers.length > 0) {
            giftRowsContainers.forEach(function (el) {
              let clone = getGiftRowTemplate()
              const amountField = clone.querySelector('.amount')
              const editButton = clone.querySelector('.gift-edit')
              const removeButton = clone.querySelector('.gift-remove')

              clone.querySelector('.gift-label').innerText = giftFundText
              amountField.value = giftAmount
              clone.querySelector('.designation').value = giftFundCategory
              clone.querySelector('.designation-unit').value = giftFundUnit
              clone.querySelector('.designation-area').value = giftFundArea
              clone.querySelector('.designation-fund').value = giftFundId
              clone.querySelector('.designation-search').value = false
              //if (editStatus === '1' || giftFundCategory === '') {
              if (editStatus === '1') {
                hideElement(editButton)
                amountField.addEventListener('change', function () {
                  increaseOnly(giftAmount)
                })
                //} else if (editStatus === '2' || giftFundCategory === '') {
              } else if (editStatus === '2') {
                hideElement(editButton)
                amountField.disabled = true
              }
              if (removeStatus === '1') {
                hideElement(removeButton)
              }
              el.insertBefore(clone, el.querySelector('.my-gift-add'))
            })
            updateGiftAmountTotal()
          }
        }

        function clearGiftFields() {
          hideFunds()
          clearUnits()
          clearSpecifiedFund()
          hideGiftLevels()
          document.getElementById('designation-1').checked = true
          triggerEvent(document.getElementById('designation-1'), 'click')
          document.getElementById('gift-amount-1').checked = true
          triggerEvent(document.getElementById('gift-amount-1'), 'click')
          document.getElementById('designation-search').value = ''
          triggerEvent(document.getElementById('designation-search'), 'change')
          document.getElementById('designation-search').value = ''
        }

        function getRecurringPayments() {
          const numberOfInstallments = document.getElementById('gift-num-payments').value
          let giftFrequency = theForm.querySelector('[name="gift-type"]:checked').nextElementSibling.innerText
          const totalGiftAmount = document.getElementById('gift-amount-total-recurring').innerText
          const recurringGiftAmount = ds.getRecurringGiftInstallmentAmount(parseFloat(totalGiftAmount.replace(/\$/, '')), numberOfInstallments)

          if (giftFrequency === 'Annually') {
            giftFrequency = 'Annual'
          }
          return numberOfInstallments + ' ' + giftFrequency + ' Payments of $' + recurringGiftAmount + '\nTotal Gift of ' + totalGiftAmount
        }

        function getGiftFrequency() {
          return isNaN(parseInt(theForm.querySelector('[name="gift-type"]:checked').value))
            ? theForm.querySelector('[name="gift-type"]:checked').nextElementSibling.innerText
            : getRecurringPayments()
        }

        function maybeInternational() {
          return document.getElementById('country-type-2').checked
        }

        function updateReviewInfo() {
          const isInternational = maybeInternational()
          const root = document.getElementById('donor-info')

          if (root) {
            root.querySelector('.js-gift-frequency').innerText = getGiftFrequency()
            root.querySelector('.js-payment-type').innerText = theForm.querySelector('[name="gift-payment-type"]:checked').nextElementSibling.innerText
            root.querySelector('.js-donor-first-name').innerText = document.getElementById('donor-first-name').value
            root.querySelector('.js-donor-last-name').innerText = document.getElementById('donor-last-name').value
            root.querySelector('.js-donor-address').innerText = isInternational
              ? document.getElementById('donor-address-intl').value
              : document.getElementById('donor-address').value
            root.querySelector('.js-donor-city').innerText = isInternational
              ? document.getElementById('donor-city-intl').value
              : document.getElementById('donor-city').value
            root.querySelector('.js-donor-state').innerText = isInternational
              ? document.getElementById('donor-province-intl--text').value || getSelectedOptionText(document.getElementById('donor-province-intl--select'))
              : getSelectedOptionValue(document.getElementById('donor-state'))
            root.querySelector('.js-donor-zip-postal').innerText = isInternational
              ? document.getElementById('donor-zip-intl').value
              : document.getElementById('donor-zip').value
            root.querySelector('.js-donor-country').innerText = isInternational ? getSelectedOptionText(document.getElementById('donor-country')) : ''
            root.querySelector('.js-donor-email').innerText = document.getElementById('donor-email').value
            root.querySelector('.js-donor-phone').innerText = document.getElementById('donor-phone').value
          }
        }

        document.getElementById('is-corporate').addEventListener('click', function () {
          const el = document.getElementById('employer-matching')

          if (el) {
            if (this.checked) {
              if (document.getElementById('is-employer-matching').checked) {
                document.getElementById('employer-matching-control').click()
              }
              disableFields(el)
            } else {
              enableFields(el)
            }
          }
        })

        function saveForm() {
          // Save form input values
          FormPersistence.save(bbForm, {
            uuid: 'savedForm',
            useSessionStorage: true,
          })
          // Save current form HTML
          localStorage.setItem('savedHTML', JSON.stringify(theForm.innerHTML))
        }

        if (isReturning) {
          displayStep()
          setFrequency(theForm.querySelector('[name="gift-type"]:checked'))
        }
      }

      function restoreForm() {
        const modal = document.getElementById('modal-restore')
        if (modal) {
          showPage()
          activateElement(modal)
          modal.querySelectorAll('.modal-continue').forEach(function (el) {
            el.addEventListener('click', function () {
              theForm.innerHTML = JSON.parse(localStorage.getItem('savedHTML'))
              FormPersistence.load(bbForm, {
                uuid: 'savedForm',
                useSessionStorage: true,
              })
              deactivateElement(modal)
              isReturning = true
              currentStep = 1
              giftRowIndex = document.getElementById('my-gifts').querySelectorAll('.my-gift').length
              initForm()
            })
          })
          modal.querySelectorAll('.modal-reset').forEach(function (el) {
            el.addEventListener('click', function () {
              deactivateElement(modal)
              initForm()
              removeSavedData()
            })
          })
        } else {
          initForm()
        }
      }

      function loadForm() {
        if (localStorage.getItem('savedHTML') !== null) {
          restoreForm()
        } else {
          initForm()
        }
      }

      function showSpecifiedFund() {
        const specifiedFund = localStorage.getItem('specifiedFund')
        const unmappedFund = contains('.DonationFieldControlCell > span', 'Unmapped')

        if (specifiedFund && unmappedFund[0]) {
          unmappedFund[0].innerText = 'Specified fund: ' + specifiedFund
        }
      }

      function showRecurrenceSummary() {
        const recurrenceInfo = document.getElementById('confirmation-recurrence-info')

        if (recurrenceInfo) {
          const isCreditCard = document.getElementById('baylor__adf-confirmation').innerText.indexOf('Credit Card') > -1
          const frequency = recurrenceInfo.querySelector('span:first-of-type').innerText.indexOf('month') > -1 ? 'Monthly' : 'Annual'
          const startDate = new Date(recurrenceInfo.querySelector('span:nth-of-type(2)').innerText)
          const endDate = new Date(recurrenceInfo.querySelector('span:nth-of-type(4)').innerText)
          const numPayments =
            frequency === 'Monthly'
              ? getMonthDiff(startDate, endDate) + 1
              : isCreditCard
              ? getYearDiff(startDate, endDate) + 1
              : getYearDiff(startDate, endDate)
          let giftAmount = document.getElementById('confirmation-gift-amount').innerText.replace(/\$/, '').replace(/,/g, '')
          let totalGiftAmount

          giftAmount = isCreditCard ? giftAmount : numToFloat(parseFloat(giftAmount) / numPayments, 2)
          totalGiftAmount = String(numToFloat(parseFloat(giftAmount) * numPayments, 2))
          recurrenceInfo.innerText = numPayments + ' ' + frequency + ' Payments of $' + String(giftAmount) + '\nTotal Gift of $' + totalGiftAmount
        }
      }

      function showDirectDebitSummary() {
        const isDirectDebit = document.querySelector('.BBDonationConfirmation').innerText.indexOf('Direct Debit') > -1

        if (isDirectDebit) {
          const payOptRow = contains('.DonationFieldCaption', 'Payment Option')

          if (payOptRow.length > 0) {
            const el = getClosestEl(payOptRow[0], 'dl')
            if (el) {
              hideElement(el)
            }
          }
        }
      }

      function setupConfirmation() {
        addClass(document.body, 'is-confirmation')
        showElement(document.querySelector('.baylor__banner'))
        hideElement(document.querySelector('.baylor__banner ~ .baylor__banner'))
        showPage()
        removeSavedData()
      }

      function insertConfirmationHtml(confirmationHtml) {
        if (confirmationHtml) {
          document.getElementById('baylor__adf-confirmation').innerHTML = confirmationHtml
        }
        showSpecifiedFund()
        showRecurrenceSummary()
        showDirectDebitSummary()
      }

      function getConfirmation(donationId) {
        const success = function (returnedHtml) {
          insertConfirmationHtml(returnedHtml)
        }

        const error = function (returnedErrors) {
          console.log(returnedErrors)
        }

        setupConfirmation()
        ds.getDonationConfirmationHtml(donationId, success, error)
      }

      function completeDonation() {
        const success = function (returnedDonation) {
          getConfirmation(returnedDonation.Id)
        }

        const error = function (returnedErrors) {
          if (returnedErrors[0].ErrorCode === 203) {
            loadForm()
          }
        }

        ds.completeBBSPDonation(donationId, success, error)
      }

      if (theConfirmation && donationId) {
        completeDonation()
      } else if (theForm) {
        loadForm()
      }
    }
    initBBIS()
  })

  // Array functions
  function chunkArray(arr, chunkSize) {
    const arrayLength = arr.length
    let tempArray = []

    for (let index = 0; index < arrayLength; index += chunkSize) {
      let chunk = arr.slice(index, index + chunkSize)
      tempArray.push(chunk)
    }
    return tempArray
  }

  function dynamicSort(property) {
    let sortOrder = 1
    if (property[0] === '-') {
      sortOrder = -1
      property = property.substr(1)
    }
    return function (a, b) {
      let result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
      return result * sortOrder
    }
  }

  // CSS class functions
  function hasClass(el, cls) {
    return el.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
  }

  function clearClass(el) {
    el.className = ''
  }

  function addClass(el, cls) {
    if (!hasClass(el, cls)) el.className += ' ' + cls
  }

  function removeClass(el, cls) {
    if (hasClass(el, cls)) {
      let reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
      el.className = el.className.replace(reg, ' ')
    }
  }

  function activateElement(el) {
    addClass(el, 'is-active')
  }

  function deactivateElement(el) {
    removeClass(el, 'is-active')
  }

  function hideElement(el) {
    if (el) {
      addClass(el, 'is-hidden')
    }
  }

  function showElement(el) {
    if (el) {
      removeClass(el, 'is-hidden')
    }
  }

  // Date functions
  function getMonthDiff(dateFrom, dateTo) {
    return dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear())
  }

  function getYearDiff(dateFrom, dateTo) {
    return dateTo.getFullYear() - dateFrom.getFullYear()
  }

  function addMonths(date, months) {
    let d = date.getDate()

    date.setMonth(date.getMonth() + +months)
    if (date.getDate() != d) {
      date.setDate(0)
    }
    return date
  }

  // DOM functions
  function wrap(node, wrapper) {
    node.parentNode.insertBefore(wrapper, node)
    wrapper.appendChild(node)

    return wrapper
  }

  function wrapAll(nodes, wrapper) {
    let parent = nodes[0].parentNode
    let previousSibling = nodes[0].previousSibling

    for (let i = 0; nodes.length - i; wrapper.firstChild === nodes[0] && i++) {
      wrapper.appendChild(nodes[i])
    }

    let nextSibling = previousSibling ? previousSibling.nextSibling : parent.firstChild
    parent.insertBefore(wrapper, nextSibling)
    return wrapper
  }

  function contains(selector, text) {
    const els = getAll(selector)
    return [].filter.call(els, function (el) {
      return RegExp(text).test(el.textContent)
    })
  }

  function clearElement(el) {
    while (el.firstChild) {
      el.removeChild(el.firstChild)
    }
  }

  function getAll(selector, root = document) {
    return Array.prototype.slice.call(root.querySelectorAll(selector), 0)
  }

  function getClosestEl(el, selector) {
    let closestNode = el.closest(selector)

    return closestNode ? closestNode : null
  }

  function getSiblings(el) {
    let siblings = []
    let sibling = el.parentNode.firstChild

    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== el) {
        siblings.push(sibling)
      }
      sibling = sibling.nextSibling
    }
    return siblings
  }

  function getNextSiblings(el, filter) {
    let siblings = []

    while ((el = el.nextSibling)) {
      if (!filter || filter(el)) siblings.push(el)
    }
    return siblings
  }

  function insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling)
  }

  // Event functions
  function triggerEvent(el, evt) {
    el.dispatchEvent(new Event(evt))
  }

  // Input functions
  function increaseOnly(minAmount) {
    if (event.target.value < minAmount || isNaN(parseFloat(event.target.value))) {
      event.target.value = minAmount
    }
  }

  function clearValue(el) {
    el.value = ''
  }

  function addPlaceholder(el, placeholder) {
    el.placholder = placeholder
  }

  // Input select-one functions
  function getSelectedOptionAttribute(el, attr) {
    return el.options[el.selectedIndex].getAttribute(attr)
  }

  function getSelectedOptionText(el) {
    return el.options[el.selectedIndex].text
  }

  function getSelectedOptionValue(el) {
    return el.options[el.selectedIndex].value
  }

  function selectOption(el, valueToSelect) {
    el.value = valueToSelect
  }

  function clearOptions(el) {
    while (el.childNodes.length > 2) {
      el.removeChild(el.lastChild)
    }
  }

  function createDesignationOption(el, option) {
    let opt = document.createElement('option')

    opt.value = option.guid
    opt.text = option.name
    opt.setAttribute('data-lookupId', option.lookupId)
    if (option.redirectURL !== '') {
      opt.setAttribute('data-redirectURL', option.redirectURL)
    }
    return el.nodeName.toLowerCase() === 'select' ? el.add(opt) : el.appendChild(opt)
  }

  function createISOOption(el, obj) {
    let opt = document.createElement('option')

    opt.value = obj.Id
    opt.text = obj.Description
    opt.setAttribute('iso', obj.ISO)
    el.add(opt)
  }

  function createOptGroup(dropdown, group, objs) {
    let optGroup = document.createElement('optgroup')

    optGroup.setAttribute('label', '[' + group + ']')
    objs.forEach(function (obj) {
      createDesignationOption(optGroup, obj)
    })
    dropdown.appendChild(optGroup)
  }

  function createOption(el, option) {
    let opt = document.createElement('option')

    opt.value = option
    opt.text = option
    el.add(opt)
  }

  function addBlankOption(el) {
    return el.add(new Option('\u2014 Select \u2014', '', true, true), el.options[0])
  }

  // Location functions
  function getUrlVars() {
    let vars = {}
    let parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value
    })
    return vars
  }

  // Number functions
  function numToFloat(num, decPlaces) {
    return num.toFixed(decPlaces)
  }

  //Property functions
  function disableFields(el) {
    const fields = el.querySelectorAll('input, select, textarea')

    el.disabled = true
    if (fields.length > 0) {
      fields.forEach(function (field) {
        field.disabled = true

        switch (field.type.toLowerCase()) {
          case 'checkbox' || 'radio':
            field.checked = false
            break
          case 'select-one' || 'textarea':
            field.value = ''
            break
          default:
            field.value = ''
        }
      })
    }
  }

  function enableFields(el) {
    const fields = el.querySelectorAll('input, select, textarea')

    el.disabled = false
    if (fields.length > 0) {
      fields.forEach(function (field) {
        field.disabled = false
      })
    }
  }

  function makeRequired(el) {
    el.required = true
  }

  // String functions
  function updateText(el, str) {
    el.innerText(str)
  }

  // Misc functions
  function hidePage() {
    document.body.setAttribute('style', 'position: fixed; width: 100%;')
    document.querySelector('main .container').setAttribute('style', 'opacity: 0 !important;')
  }

  function showPage() {
    document.body.setAttribute('style', 'position: static')
    document.querySelector('.baylor__header').setAttribute('style', 'position: relative !important;')
    document.querySelector('.pageloader').setAttribute('style', 'transform: translateY(-200%) !important;')
    document.querySelector('main .container').setAttribute('style', 'opacity: 1 !important;')
  }

  function goToTop() {
    window.scrollTo(0, 0)
  }
})()
